import * as s from './ArCareersHero.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import { METRICS } from 'src/config/metrics'
import React from 'react'
import { TarCareersHeroProps } from './ArCareersHero.types'
import { parseRichText } from 'src/utils/parseRichText'

export const ArCareersHero: React.FC<TarCareersHeroProps> = () => {
  return (
    <ArContainer className={s.container}>
      <div className="text-center mx-auto flex flex-col gap-4">
        <div className="text-center mx-auto max-w-3xl">
          <h1>Help us build technology for the next era of insurance.</h1>
        </div>
        <div className="max-w-xl mx-auto">
          <p>
            We're looking for exceptional talent to join us in creating technology that propels the insurance industry
            forward.
          </p>
        </div>
      </div>
    </ArContainer>
  )
}
