import * as s from './ArQuickLinks.module.scss'

import React from 'react'
import SvgArrowRight from 'src/assets/svg/arrow-right-3.svg'
import { TArQuickLinksProps } from './ArQuickLinks.types'

export const ArQuickLinks: React.FC<TArQuickLinksProps> = ({ title, paragraph, links }) => {
  return (
    <div className={s.container}>
      <h3>{title}</h3>
      <p>{paragraph}</p>
      <ul>
        {links.map((item, i) => (
          <li key={i}>
            <a href={item.url} target="_blank" rel="noreferrer">
              <p>{item.title}</p>
              <SvgArrowRight />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
